import React  from 'react';
import styled from 'styled-components';

const Cap = styled.span`
  background-color: ${props => props.theme.colors.brandColor};
  border-radius: 3px;
  color: #fff;
  font-size: 2.2rem;
  margin: 0 6px 0 0;
  padding: 6px;
  display: inline-flex;
`;

function Dropcap(props) {
  const Tag = props.children.type;
  const string = props.children.props.children;
  const firstChar = string.charAt(0);
  const restString = string.substring(1, string.length);
  return (
    <Tag>
      <Cap>{firstChar}</Cap>{restString}
    </Tag>
  )
}

export default Dropcap;
