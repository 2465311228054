import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Spacer        from '../components/Spacer';
import Heading       from '../components/Heading';
import Wrapper       from '../components/Wrapper';
import Carousel      from '../components/Carousel';
import Dropcap       from '../components/Dropcap';
import { J }         from '../components/Text';
import CallToAction  from '../modules/CallToAction';
import DefaultLayout from '../layouts/Default';

class MaterialienPage extends React.Component {
  render() {
    return (
      <DefaultLayout location={this.props.location}>
        <Carousel>
          {this.props.data.headerImages.edges.map((image) => (
            <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
          ))}
        </Carousel>
        <Wrapper>
          <Spacer/>
          <Heading>Materialien</Heading>
          <article>
            <Dropcap><h3>Gelbgold</h3></Dropcap>
            <J>
              Das klassische Edelmetall für Trauringe ist Gelbgold. Sie können wählen zwischen den Legierungen 333/000,
              585/000 und 750/000. Die Unterschiede erklären wir Ihnen gerne in unserem Trauringstudio.
            </J>
          </article>
          <article>
            <Dropcap><h3>Weissgold</h3></Dropcap>
            <J>
              Weißgold kommt in der Natur nicht vor. Es entsteht durch eine Legierung von Gelbgold und anderen weißen
              Materialien. Da lediglich der Goldanteil (333, 585, 750 von tausend Anteilen) auf dem Schmuckstück
              punziert werden muss, resultieren daraus große Qualitätsunterschiede, je nachdem mit welchen anderen
              Metallen das Gold legiert wird. Nickel kommt bei uns nicht vor! So wollen wir Reklamationen Ihrerseits
              vermeiden. Zu beachten ist allerdings, dass Weißgold erst durch eine Rhodinierung seine helle weiße Farbe
              erlangt. Dies ist eine Beschichtung mit Rhodium, die je nach Beanspruchung von Zeit zu Zeit erneuert
              werden muss.
            </J>
          </article>
          <article>
            <Dropcap><h3>Graugold</h3></Dropcap>
            <J>
              Graugold ist ebenso wie Weißgold eine Legierung aus Gold und anderen Metallen. Die graue Farbe entsteht
              durch die Beimischung von Palladium (18-20%), was die Ringe farbecht werden lässt und allergische
              Reaktionen minimiert.
            </J>
          </article>
          <article>
            <Dropcap><h3>Rosé- und Rotgold</h3></Dropcap>
            <J>
              Durch die Beimischung von Kupfer in verschieden hohen Anteilen entstehen Rosé- oder Rotgoldringe. Gerade
              in der Kombination mit Weißgold sind diese Trauringe bei uns zur Zeit sehr gefragt. Wir zeigen Ihnen gerne
              viele verschiedene Muster in unserem Trauringstudio.
            </J>
          </article>
          <article>
            <Dropcap><h3>Platin</h3></Dropcap>
            <J>
              Platin ist das edelste aller Edelmetalle. Es ist das ideale Metall für Trauringe, da es relativ kratzfest
              ist, die Diamanten besonders gut einschließt und einen äußerst geringen Abrieb hat. Dadurch werden Ihre
              Trauringe nicht dünner. Platin hat eine helle, weiße Farbe, die sich im Laufe des Lebens nicht verändert.
              Dies alles gilt nur für die Legierung von 950/000 Platin. Alternativ bieten wir Ihnen aber auch andere
              Platin-Legierungen an.
            </J>
          </article>
          <article>
            <Dropcap><h3>Palladium</h3></Dropcap>
            <J>
              Palladium ist im Schmuckbereich ein relativ neues Material. Aber die Nachfrage nach Palladium steigt, da
              es viele positive Eigenschaften hat:

              - Palladium verändert seine Farbe nicht
              - Palladium ist beständiger gegen Abrieb als Weißgold
              - Palladium ist leichter als Gold und deshalb preislich oft günstiger
            </J>
          </article>
          <article>
            <Dropcap><h3>Silber und Palladiumsilber</h3></Dropcap>
            <J>
              Silber ist als Trauring-Material nur bedingt geeignet, da es sehr weich ist und die Oberfläche recht
              schnell verkratzt. Als Alternative bieten wir Ihnen eine Legierung aus Silber mit Palladium an, deren
              Eigenschaften sehr gut dazu geeignet sind, einen langlebigen Trauring herzustellen. Diese Ringe sind
              deutlich preiswerter als Weißgold oder Palladium. Viele Trauringmuster können wir Ihnen in dieser neuen
              Legierung anbieten.
            </J>
          </article>
          <article>
            <Dropcap><h3>Edelstahl und Titan</h3></Dropcap>
            <J>
              Auch Ringe aus Edelstahl oder Titan sind nur bedingt als Trauring geeignet. Sie sind sehr viel härter als
              Ringe aus Gold, Silber, Platin oder Palladium. Dadurch sind die Tragespuren relativ gering. Allerdings
              lassen sie sich deshalb nicht mehr nachträglich in der Größe verändern. Und Ihre Hände werden sich im
              Laufe ihres Lebens verändern. Dann passt ihr Ring nicht mehr an ihren Finger. Das sollten Sie beim Kauf
              dieser Ringe beachten.
            </J>
          </article>
          <article>
            <Dropcap><h3>Damaszener Stahl</h3></Dropcap>
            <J>
              Trauringe aus Damaszener Stahl sind Unikate, kein Ring ist wie der andere. Durch das Schmieden mehrerer
              Schichten unterschiedlichen Stahls in sich abwechselnden Lagen entstehen einzigartige Muster. Als
              Untermaterial sind Silber, Palladium oder Gold möglich.
            </J>
          </article>
          <article>
            <Dropcap><h3>Tantal</h3></Dropcap>
            <J>
              Tantal ist eines der seltensten Elemente unseres Sonnensystems. Der einzigartige, anthrazit-dunkle Farbton
              macht das Metall unverkennbar. Es ist 5mal härter als Platin und damit sehr oberflächenbeständig gegenüber
              den alltäglichen äußeren Einflüssen. In seiner Verwendung im Schmuckbereich ist Tantal zu 100%
              unbedenklich und hautverträglich. Tantal ist ganz klar etwas Besonderes - selten, langlebig, edel.
            </J>
          </article>
          <article>
            <Dropcap><h3>Carbon</h3></Dropcap>
            <J>
              Carbon ist ein Kunststoff, der sehr geschmeidig und dennoch robust ist. Carbon ist leicht und somit
              angenehm zu tragen. Seine Farbe ist einzigartig. Wir führen Carbon-Ringe in tiefsattem schwarz, aber auch
              in grau. Carbon kann mit den verschiedensten Edelmetallen verarbeitet werden.
            </J>
          </article>
          <Spacer/>
          <CallToAction/>
          <Spacer/>
        </Wrapper>
      </DefaultLayout>
    )
  }
}

export default MaterialienPage;

export const query = graphql`
  query MaterialsPageQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/index"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
  }
`;
